const CSS_ACTIVE = "is-active";
const CSS_FIXED = "is-fixed";
const CSS_ANIMATED = "is-animated";
const CSS_OPEN = "is-open";
const CSS_COLLAPSED = "is-collapsed";
const VARIANT_BUTTON = "SubNavigationItem--button";
const VARIANT_DROPDOWN = "SubNavigationItem--dropdown";

const SubNavigation = {
    elements: [],
    handlers: {},
    init: (PageComponents, container = null) => {
        SubNavigation.elements = document.querySelectorAll('.SubNavigation');
        SubNavigation.elements.forEach((subNavElem, index) => {
            const globalNavContainer = document.querySelector('.containWrap, .HeaderNavigationBar');
            // todo: remove me after new nav goes live
            // Commenting for vis: Has this happened? Assuming globalNavContainer to be removed?
            const mobileNavCover = document.querySelector('.mobileLogo');
            const subNavWrapper = subNavElem.querySelector('.SubNavigation-wrapper');
            const subNavScroller = subNavElem.querySelector('.SubNavigation-scroller');
            const subNavContent = subNavElem.querySelector('.SubNavigation-content');
            const subNavUnderline = subNavElem.querySelector('.SubNavigation-underline');

            // Dropdown behavior
            const subNavDropdown = subNavElem.querySelector('.SubNavigationDropdown');
            function setDropdownLabel(setDefault = false) {
                const dropdownLabel = subNavElem.querySelector('.SubNavigationDropdown-label');
                const dropdownText = subNavElem.querySelector('.SubNavigationDropdown-text');

                if (dropdownLabel && dropdownText) {
                    dropdownText.textContent = dropdownText.getAttribute('data-label');
                    if (setDefault) {
                        const labelTag = dropdownLabel.querySelector('.SubNavigationItem-tag');
                        if (labelTag != null) {
                            dropdownLabel.removeChild(labelTag);
                        }
                        return;
                    }
                    const activeSelection = subNavElem.querySelector('.SubNavigationItem-link[data-value="' + dropdownText.getAttribute('data-value') + '"]');
                    activeSelection.parentElement.classList.add(CSS_ACTIVE);
                    const selectionTag = subNavElem.querySelector('.SubNavigationItem-tag[data-label="' + activeSelection.getAttribute('data-label') + '"]');
                    if (selectionTag != null && !dropdownLabel.firstElementChild.classList.contains('SubNavigationItem-tag')) {
                        dropdownLabel.insertBefore(selectionTag.cloneNode(true), dropdownLabel.firstChild);
                        dropdownText.textContent = activeSelection.textContent;
                    } else {
                        dropdownText.textContent = activeSelection.textContent;
                    }
                }
            }
            if (subNavDropdown != null) {
                setDropdownLabel();
            }

            // Sync nav with page section
            let pageSections = [];
            function checkActiveSection() {
                requestAnimationFrame(() => {
                    const midpoint = window.innerHeight / 2;
                    pageSections.forEach((token, index) => {
                        const anchor = subNavElem.querySelector('.SubNavigationItem[data-target="' + token + '"]');
                        const section = document.getElementById(token)?.getBoundingClientRect();
                        if (anchor && section) {
                            let isActive;
                            if (index === 0) {
                                isActive = midpoint < section.bottom;
                            } else if (index === (pageSections.length - 1)) {
                                isActive = section.top < midpoint;
                            } else {
                                isActive = section.top < midpoint && midpoint < section.bottom;
                            }
                            if (isActive && !anchor.classList.contains(CSS_ACTIVE)) {
                                anchor.classList.add(CSS_ACTIVE);
                                moveSubNavUnderline(anchor);
                            } else {
                                anchor.classList.toggle(CSS_ACTIVE, isActive);
                            }
                        }
                    });
                });
            }

            function subNavScrollHandler() {
                checkActiveSection();
                subNavElem.style.height = subNavWrapper.offsetHeight + 'px';

                let navBottom = 0;
                if (globalNavContainer) {
                    const globalNavBox = globalNavContainer.getBoundingClientRect();
                    navBottom = globalNavBox.bottom;
                }
                // fixme: remove me with new nav in place
                if (mobileNavCover) {
                    const mobileNavStyle = window.getComputedStyle(document.querySelector('.mobileHeader'));
                    if (mobileNavStyle.display !== 'none') {
                        navBottom = mobileNavCover.getBoundingClientRect().bottom;
                    }
                }
                const subNavBox = subNavElem.getBoundingClientRect();
                if (subNavBox.top < navBottom) {
                    subNavElem.classList.add(CSS_FIXED);
                    subNavElem.querySelector('.SubNavigation-wrapper').style.top = navBottom + 'px';
                } else {
                    subNavElem.classList.remove(CSS_FIXED);
                    subNavElem.querySelector('.SubNavigation-wrapper').style.top = null;
                }
            }
            document.addEventListener("scroll", subNavScrollHandler);

            // Align underline on page sub-nav
            function moveSubNavUnderline(element, animate = true) {
                moveSubNavUnderlineTo(element.offsetLeft, element.offsetWidth, animate);
            }
            function moveSubNavUnderlineTo(left, width, animate = true) {
                subNavUnderline.classList.toggle(CSS_ANIMATED, animate);
                subNavUnderline.style.left = left + 'px';
                subNavUnderline.style.width = width + 'px';
            }

            let navAnchors = subNavElem.querySelectorAll('.SubNavigationItem');
            for (const el of navAnchors) {
                if (el.dataset.target && !pageSections.includes(el.dataset.target)) {
                    pageSections.push(el.dataset.target);
                }
            }
            subNavScrollHandler();

            let activeSubNavItem = subNavElem.querySelector('.SubNavigationItem.is-active');
            if (activeSubNavItem) {
                moveSubNavUnderline(activeSubNavItem, false);
            }
            for (let i = 0; i < navAnchors.length; i++) {
                if (navAnchors[i].classList.contains(CSS_ACTIVE)) continue;
                if (navAnchors[i].classList.contains(VARIANT_BUTTON)) continue;
                if (navAnchors[i].classList.contains(VARIANT_DROPDOWN)) continue;

                navAnchors[i].addEventListener("mouseenter", function () {
                    moveSubNavUnderline(navAnchors[i]);
                });
                navAnchors[i].addEventListener("mouseleave", function () {
                    const activeElem = subNavElem.querySelector('.SubNavigationItem.is-active');
                    if (activeElem) {
                        moveSubNavUnderline(activeElem);
                    }
                });
            }

            let resizeSubNavDebounce = null;
            function subNavResizeHandler() {
                const activeElem = subNavElem.querySelector('.SubNavigationItem.is-active');
                if (activeElem) {
                    moveSubNavUnderline(activeElem, false);
                }

                if (resizeSubNavDebounce != null) {
                    clearTimeout(resizeSubNavDebounce);
                }
                resizeSubNavDebounce = setTimeout(resizeSubNav, 50);
            }
            function resizeSubNav() {
                requestAnimationFrame(() => {
                    const activeElem = subNavElem.querySelector('.SubNavigationItem.is-active');

                    subNavElem.classList.remove(CSS_COLLAPSED);
                    subNavContent.classList.remove(CSS_COLLAPSED);
                    if (subNavDropdown) {
                        subNavDropdown.classList.remove(CSS_COLLAPSED);
                        subNavDropdown.querySelectorAll('.SubNavigationItem--dropdown').forEach((dropdownElement) => {
                            dropdownElement.classList.remove(CSS_COLLAPSED);
                        });
                    }
                    if (subNavContent.offsetWidth > subNavWrapper.offsetWidth) {
                        subNavElem.classList.add(CSS_COLLAPSED);
                        subNavContent.classList.add(CSS_COLLAPSED);
                        if (subNavDropdown) {
                            subNavDropdown.classList.add(CSS_COLLAPSED);
                            setDropdownLabel(true);
                            subNavDropdown.querySelectorAll('.SubNavigationItem--dropdown').forEach((dropdownElement) => {
                                dropdownElement.classList.add(CSS_COLLAPSED);
                            });
                        }
                    }
                    subNavScrollHandler();
                });
            }
            window.addEventListener("resize", subNavResizeHandler);
            window.addEventListener("load", resizeSubNav);
            subNavResizeHandler();

            subNavElem.querySelector('.SubNavigation-down').addEventListener('click', (e) => {
                e.stopPropagation();
                e.preventDefault();
                subNavElem.classList.toggle(CSS_OPEN);
                subNavContent.classList.toggle(CSS_OPEN);
                if (subNavDropdown) subNavDropdown.classList.toggle(CSS_OPEN);
                subNavResizeHandler();
            });

            SubNavigation.handlers[index] = {
                mobileNavCover,
                subNavWrapper,
                subNavScroller,
                subNavContent,
                subNavUnderline,
                moveSubNavUnderline,
                moveSubNavUnderlineTo,
                subNavResizeHandler,
                subNavScrollHandler,
            }
        });

        PageComponents.SubNavigation = SubNavigation;
    }
}

module.exports = SubNavigation;
