const Carousel = {
    DIRECTION_PREV: "prev",
    DIRECTION_NEXT: "next",

    instances: {},

    init: (PageComponents, container = null) => {

        const carouselElems = document.querySelectorAll(".Carousel");
        for (let i = 0; i < carouselElems.length; ++i) {
            let isWraparound = true;
            const carouselElem = carouselElems[i];
            const wrapperElem = carouselElem.querySelector(".Carousel-wrapper");
            const contentElem = carouselElem.querySelector(".Carousel-content");
            const parentId = carouselElem.getAttribute("data-parent");
            const slides = carouselElem.querySelectorAll(".CarouselSlide");
            const findActiveSlide = () => {
                for (let n = 0; n < slides.length; ++n) {
                    if (slides[n].classList.contains("is-active")) {
                        return {
                            index: n,
                            slide: slides[n]
                        }
                    }
                }
                return {
                    index: -1,
                    slide: null
                }
            }
            const CarouselData = {
                animating: false,
                elem: carouselElem,
                wrapperElem,
                contentElem,
                slides,
                findActiveSlide,
                beforeChangeCallbacks: [],
                afterChangeCallbacks: [],
            }
            CarouselData.beforeChange = (callback) => {
                CarouselData.beforeChangeCallbacks.push(callback);
            }
            CarouselData.afterChange = (callback) => {
                CarouselData.afterChangeCallbacks.push(callback);
            }
            CarouselData.getPrevSlide = () => {
                const {index: currentIndex} = findActiveSlide();
                let previousIndex = currentIndex - 1;
                if (previousIndex < 0) {
                    if (!isWraparound) return null;

                    previousIndex = slides.length - 1;
                }
                return slides[previousIndex];
            }
            CarouselData.getNextSlide = () => {
                const {index: currentIndex} = findActiveSlide();
                let nextIndex = currentIndex + 1;
                if (nextIndex >= slides.length) {
                    if (!isWraparound) return null;

                    nextIndex = 0;
                }
                return slides[nextIndex];
            }

            if (carouselElem.classList.contains("Carousel--rotating")) {
                // Only allow wraparound functionality if we have 3 slides to use
                isWraparound = slides.length > 2;

                CarouselData.selectSlide = (index, options = {}) => {
                    index = Math.max(0, Math.min(index, slides.length - 1));
                    const {slide: oldSlide, index: oldIndex} = findActiveSlide();
                    if (oldIndex === index) return;

                    if (!options.suppressCallbacks) {
                        CarouselData.beforeChangeCallbacks.forEach((callback) => {
                            callback({
                                oldSlide,
                                oldIndex,
                                newSlide: slides[index],
                                newIndex: index,
                                direction: options.direction,
                            });
                        });
                    }

                    slides.forEach((slide, i) => {
                        slide.classList.remove("is-animating", "is-animatingLeft", "is-animatingRight", "is-beforePrev", "is-afterNext", "is-wraparound");
                        slide.classList.toggle("is-active", i === index);
                        slide.classList.toggle("is-prev", index === (i > 0 ? i - 1 : (slides.length - 1)));
                        slide.classList.toggle("is-next", index === (i < (slides.length - 1) ? i + 1 : 0));
                    });

                    if (!options.suppressCallbacks) {
                        CarouselData.afterChangeCallbacks.forEach((callback) => {
                            callback({
                                oldSlide,
                                oldIndex,
                                newSlide: slides[index],
                                newIndex: index,
                                direction: options.direction,
                            });
                        });
                    }
                };

                CarouselData.nextSlide = (e, options = {}) => {
                    if (e) {
                        e.preventDefault();
                        e.stopPropagation();
                    }
                    if (CarouselData.animating) return;
                    const {slide: currentSlide, index: currentIndex} = findActiveSlide();
                    if (!isWraparound && currentIndex >= (slides.length - 1)) {
                        CarouselData.prevSlide(null, options);
                        return;
                    }

                    CarouselData.animating = true;
                    let previousSlide = CarouselData.getPrevSlide();
                    let nextSlide = CarouselData.getNextSlide();
                    let afterNextIndex = currentIndex + 2;
                    if (afterNextIndex >= slides.length && isWraparound) {
                        afterNextIndex -= slides.length;
                    }
                    const afterNextSlide = (afterNextIndex < slides.length && afterNextIndex !== currentSlide) ? slides[afterNextIndex] : null;

                    if (!options.suppressCallbacks) {
                        CarouselData.beforeChangeCallbacks.forEach((callback) => {
                            callback({
                                oldSlide: currentSlide,
                                oldIndex: parseInt(currentSlide.getAttribute("data-index")),
                                newSlide: nextSlide,
                                newIndex: parseInt(nextSlide.getAttribute("data-index")),
                                direction: Carousel.DIRECTION_NEXT,
                            });
                        });
                    }

                    // Animate start
                    if (previousSlide) previousSlide.classList.add("is-animatingLeft")
                    currentSlide.classList.add("is-animatingLeft")
                    if (nextSlide) nextSlide.classList.add("is-animatingLeft")
                    if (afterNextSlide) {
                        if (afterNextSlide === previousSlide) {
                            afterNextSlide.classList.add("is-wraparound")
                        } else {
                            afterNextSlide.classList.add("is-afterNext")
                            afterNextSlide.classList.add("is-animating")
                        }
                    }

                    // Animate finish
                    setTimeout(() => {
                        requestAnimationFrame(() => {
                            if (previousSlide) {
                                previousSlide.classList.remove("is-animatingLeft")
                                previousSlide.classList.remove("is-prev")
                            }

                            currentSlide.classList.remove("is-animatingLeft")
                            currentSlide.classList.remove("is-active")
                            currentSlide.classList.add("is-prev")

                            if (nextSlide) {
                                nextSlide.classList.remove("is-animatingLeft")
                                nextSlide.classList.remove("is-next")
                                nextSlide.classList.add("is-active")
                            }

                            if (afterNextSlide) {
                                if (afterNextSlide === previousSlide) {
                                    afterNextSlide.classList.remove("is-wraparound")
                                } else {
                                    afterNextSlide.classList.remove("is-afterNext")
                                    afterNextSlide.classList.remove("is-animating")
                                }
                                afterNextSlide.classList.add("is-next")
                            }
                            CarouselData.animating = false;

                            if (!options.suppressCallbacks) {
                                CarouselData.afterChangeCallbacks.forEach((callback) => {
                                    callback({
                                        oldSlide: currentSlide,
                                        oldIndex: parseInt(currentSlide.getAttribute("data-index")),
                                        newSlide: nextSlide,
                                        newIndex: parseInt(nextSlide.getAttribute("data-index")),
                                        direction: Carousel.DIRECTION_NEXT,
                                    });
                                });
                            }
                        });
                    }, 500);
                }
                CarouselData.prevSlide = (e, options = {}) => {
                    if (e) {
                        e.preventDefault();
                        e.stopPropagation();
                    }
                    if (CarouselData.animating) return;
                    const {slide: currentSlide, index: currentIndex} = findActiveSlide();
                    if (!isWraparound && currentIndex < 1) {
                        CarouselData.nextSlide(null, options);
                        return;
                    }

                    CarouselData.animating = true;
                    let previousSlide = CarouselData.getPrevSlide();
                    let nextSlide = CarouselData.getNextSlide();

                    let beforePrevIndex = currentIndex - 2;
                    if (beforePrevIndex < 0 && isWraparound) {
                        beforePrevIndex += slides.length;
                    }
                    const beforePrevSlide = (beforePrevIndex > -1 && slides[beforePrevIndex] !== currentSlide) ? slides[beforePrevIndex] : null;

                    if (!options.suppressCallbacks) {
                        CarouselData.beforeChangeCallbacks.forEach((callback) => {
                            callback({
                                oldSlide: currentSlide,
                                oldIndex: parseInt(currentSlide.getAttribute("data-index")),
                                newSlide: previousSlide,
                                newIndex: parseInt(previousSlide.getAttribute("data-index")),
                                direction: Carousel.DIRECTION_PREV,
                            });
                        });
                    }

                    // Animate start
                    if (previousSlide) previousSlide.classList.add("is-animatingRight")
                    currentSlide.classList.add("is-animatingRight")
                    if (nextSlide) nextSlide.classList.add("is-animatingRight")
                    if (beforePrevSlide) {
                        if (beforePrevSlide === nextSlide) {
                            beforePrevSlide.classList.add("is-wraparound")
                        } else {
                            beforePrevSlide.classList.add("is-beforePrev")
                            beforePrevSlide.classList.add("is-animating")
                        }
                    }

                    // Animate finish
                    setTimeout(() => {
                        requestAnimationFrame(() => {
                            if (previousSlide) {
                                previousSlide.classList.remove("is-animatingRight")
                                previousSlide.classList.remove("is-prev")
                                previousSlide.classList.add("is-active")
                            }

                            currentSlide.classList.remove("is-animatingRight")
                            currentSlide.classList.remove("is-active")
                            currentSlide.classList.add("is-next")

                            if (nextSlide) {
                                nextSlide.classList.remove("is-animatingRight")
                                nextSlide.classList.remove("is-next")
                            }

                            if (beforePrevSlide) {
                                if (beforePrevSlide === nextSlide) {
                                    beforePrevSlide.classList.remove("is-wraparound")
                                } else {
                                    beforePrevSlide.classList.remove("is-beforePrev")
                                    beforePrevSlide.classList.remove("is-animating")
                                }
                                beforePrevSlide.classList.add("is-prev")
                            }
                            CarouselData.animating = false;

                            if (!options.suppressCallbacks) {
                                CarouselData.afterChangeCallbacks.forEach((callback) => {
                                    callback({
                                        oldSlide: currentSlide,
                                        oldIndex: parseInt(currentSlide.getAttribute("data-index")),
                                        newSlide: previousSlide,
                                        newIndex: parseInt(previousSlide.getAttribute("data-index")),
                                        direction: Carousel.DIRECTION_PREV,
                                    });
                                });
                            }
                        });
                    }, 500);
                }

                const previousSlide = CarouselData.getPrevSlide();
                if (previousSlide) previousSlide.classList.add("is-prev");
                const nextSlide = CarouselData.getNextSlide();
                if (nextSlide) nextSlide.classList.add("is-next");

            }
            // Default (scrolling) carousel behavior
            else {
                CarouselData.selectSlide = (index, options = {}) => {
                    const slide = carouselElem.querySelector(`.CarouselSlide[data-index="${index}"]`);
                    if (slide) {
                        let oldSlide = null;
                        let oldIndex = -1;
                        slides.forEach((node, index) => {
                            if (node.classList.contains('is-active')) {
                                oldSlide = node;
                                oldIndex = index;
                                node.classList.remove('is-active');
                            }
                        });

                        if (!options.suppressCallbacks) {
                            CarouselData.beforeChangeCallbacks.forEach((callback) => {
                                callback({
                                    oldSlide,
                                    oldIndex,
                                    newSlide: slides[index],
                                    newIndex: index,
                                    direction: options.direction,
                                });
                            });
                        }

                        carouselElem.classList.toggle('is-animating', true);
                        slide.classList.add('is-active');
                        let offset = slide.offsetLeft;
                        //wrapperElem.scrollTo({left:slide.offsetLeft, top:0, behavior:'smooth'});
                        let completer = null;
                        if (options.wraparoundLeft) {
                            carouselElem.classList.add('is-wrappingLeft');
                            slide.classList.add('is-wrapping');
                            wrapperElem.scrollTo({left: oldSlide.offsetLeft, top: 0})
                            slide.style.left = 0;
                            offset = slide.offsetLeft;
                            completer = () => {
                                carouselElem.classList.remove('is-wrappingLeft');
                                slide.classList.remove('is-wrapping');
                                slide.style.left = null;
                                wrapperElem.scrollTo({left: slide.offsetLeft, top: 0})
                            }
                        } else if (options.wraparoundRight) {
                            carouselElem.classList.add('is-wrappingRight');
                            slide.classList.add('is-wrapping');
                            //wrapperElem.scrollTo({left: oldSlide.offsetLeft, top: 0})
                            slide.style.left = (oldSlide.offsetLeft + oldSlide.offsetWidth) + "px";
                            wrapperElem.scrollTo({left: oldSlide.offsetLeft, top: 0})
                            offset = slide.offsetLeft + slide.offsetWidth;
                            completer = () => {
                                carouselElem.classList.remove('is-wrappingRight');
                                slide.classList.remove('is-wrapping');
                                slide.style.left = null;
                                wrapperElem.scrollTo({left: slide.offsetLeft, top: 0})
                            }
                        }
                        /*
                        wrapperElem.velocity(
                            {
                                scrollLeft: offset
                            }, {
                                duration: 500,
                                complete: () => {
                                    completer && completer();

                                    if (!options.suppressCallbacks) {
                                        CarouselData.afterChangeCallbacks.forEach((callback) => {
                                            callback({
                                                oldSlide,
                                                oldIndex,
                                                newSlide: slides[index],
                                                newIndex: index,
                                                direction: options.direction,
                                            });
                                        });
                                    }
                                }
                            }
                        );
                        */
                        // fixme: for whatever reason, velocity.js refuses to work here
                        // todo: need to find a better animation library, jquery is not okay
                        $(wrapperElem).animate(
                            {
                                scrollLeft: offset
                            },
                            {
                                duration: 500,
                                complete: () => {
                                    completer && completer();
                                    carouselElem.classList.toggle('is-animating', false);

                                    if (!options.suppressCallbacks) {
                                        CarouselData.afterChangeCallbacks.forEach((callback) => {
                                            callback({
                                                oldSlide,
                                                oldIndex,
                                                newSlide: slides[index],
                                                newIndex: index,
                                                direction: options.direction,
                                            });
                                        });
                                    }
                                }
                            }
                        );
                    } else {
                        console.error(`Error: Attempted to select a non-existent slide in carousel with index=${index}`);
                    }
                };
                CarouselData.prevSlide = (e, options = {}) => {
                    let {index: activeSlideIndex} = findActiveSlide();
                    activeSlideIndex--;
                    if (activeSlideIndex < 0) {
                        activeSlideIndex = slides.length - 1;
                        options = {...options, wraparoundLeft: true};
                    }
                    options = {...options, direction: Carousel.DIRECTION_PREV};
                    CarouselData.selectSlide(activeSlideIndex, options);
                };
                CarouselData.nextSlide = (e, options = {}) => {
                    let {index: activeSlideIndex} = findActiveSlide();
                    activeSlideIndex++;
                    if (activeSlideIndex >= slides.length) {
                        activeSlideIndex = 0;
                        options = {...options, wraparoundRight: true};
                    }
                    options = {...options, direction: Carousel.DIRECTION_NEXT};
                    CarouselData.selectSlide(activeSlideIndex, options);
                };
            }

            if (parentId) {
                carouselElem.querySelector(".Carousel-prev").addEventListener("click", (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    Carousel.instances[parentId].prevSlide();
                });
                carouselElem.querySelector(".Carousel-next").addEventListener("click", (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    Carousel.instances[parentId].nextSlide();
                });

            } else {
                carouselElem.querySelector(".Carousel-prev").addEventListener("click", CarouselData.prevSlide);
                carouselElem.querySelector(".Carousel-next").addEventListener("click", CarouselData.nextSlide);
            }

            Carousel.instances[carouselElem.id] = CarouselData;
        }

        PageComponents.Carousel = Carousel;
    }
}

module.exports = Carousel;
