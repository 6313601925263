const CLASS_SELECTOR = ".AnimateWhenVisible";
const INNER_SELECTOR = ".AnimateWhenVisible-content";

let elements = [];
let initialized = false;
let lastAnimationFrame = 0;

const getAnchor = (element) => {
    const box = element.getBoundingClientRect();

    switch (element.getAttribute('data-anchor')) {
        case 'top':
            return box.top;
        case 'bottom':
            return box.bottom;
        case 'middle':
        default:
            return box.top + (box.height / 2);
    }
}

const updateElement = (element) => {
    if (!element || element.classList.contains('is-active')) return;

    const midline = getAnchor(element);
    if (midline > 0 && midline < window.innerHeight) {
        element.classList.add('is-active');
        if (element.hasAttribute('data-delay')) {
            setTimeout(
                () => {
                    element.classList.add('is-visible');
                },
                parseInt(element.getAttribute('data-delay'))
            );
        } else {
            element.classList.add('is-visible');
        }
    }
};

const update = () => {
    requestAnimationFrame((timestamp) => {
        if (timestamp > lastAnimationFrame) {
            lastAnimationFrame = timestamp;
            elements.forEach((element, index) => {
                updateElement(element);
            });
        }
    });
};

const init = (PageComponents, container=null) => {
    //if (!container || !container.querySelectorAll) {
    //    container = document;
    //}
    elements = document.querySelectorAll(CLASS_SELECTOR);
    if (!initialized && elements.length > 0) {
        window.addEventListener('resize', update);
        window.addEventListener('scroll', update);
        initialized = true;
    }
    PageComponents.AnimateWhenVisible = AnimateWhenVisible;

    update();
};

const AnimateWhenVisible = {
    update,
    init
};

module.exports = AnimateWhenVisible;
