const CLASS_SELECTOR = ".CollapsibleFAQ";
const ANSWER_SELECTOR = ".CollapsibleFAQ-answer";
const INNER_SELECTOR = ".CollapsibleFAQ-answerInner";

const CollapsibleFAQ = {
    elements: null,

    toggle: (element) => {
        element.classList.toggle('is-open');
        const isOpen = element.classList.contains('is-open');
        const container = element.querySelector(ANSWER_SELECTOR);
        const inner = element.querySelector(INNER_SELECTOR);
        container.style.height = (isOpen ? inner.offsetHeight : 0) + 'px';
    },

    initElements: (container) => {
        if (!container || !container.querySelectorAll) {
            container = document;
        }
        const elements = container.querySelectorAll(CLASS_SELECTOR);
        elements.forEach((element, index) => {
            if (!element.hasAttribute('data-initialized')) {
                element.addEventListener(
                    'click',
                    CollapsibleFAQ.toggle.bind(null, element)
                );
                if (Warframe && Warframe.addDataLayerListener) {
                    Warframe.addDataLayerListener({
                        element,
                        event: 'click',
                        payload: {
                            event_name: 'accordion_click',
                            creative_type: 'accordion',
                            creative_title: 'open/close',
                            creative_placement: Warframe.detectAnalyticsPlacement(element),
                        },
                        transform: (e, p) => {
                            const isOpen = element.classList.contains('is-open');
                            p.creative_title = isOpen ? 'open' : 'close';
                        },
                    })
                }
                element.setAttribute('data-initialized', 'true');
            }
        });
        CollapsibleFAQ.elements = document.querySelectorAll(CLASS_SELECTOR);
    },

    init: (PageComponents, container=null) => {
        CollapsibleFAQ.initElements(container);

        PageComponents.CollapsibleFAQ = CollapsibleFAQ;
    },
};

module.exports = CollapsibleFAQ;
